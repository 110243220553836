import { DefaultTheme } from "styled-components";

const size = {
  mobile: "1024px",
  tablet: "1024px",
  laptop: "1024px",
  desktop: "1024px",
};

export const theme: DefaultTheme = {
  red: "#E51013",
  black: {
    veryDark: "#141414",
    darker: "#181818",
    lighter: "#2F2F2F",
  },
  white: {
    lighter: "#fff",
    darker: "#e5e5e5",
  },

  mobile: `(max-width: ${size.mobile})`,
  mobile_min: `(min-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  tablet_min: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptop_min: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
  desktop_min: `(min-width: ${size.desktop})`,

  title_mobile_font: "60px",

  headline1: {
    font_size: "120px",
    line_height: "110px",
    font_weight: "700",
    m_font_size: "68px",
    m_line_height: "72px",
  },
  headline2: {
    font_size: "60px",
    line_height: "58px",
    font_weight: "700",
    m_font_size: "40px",
    m_line_height: "40px",
  },
  headline3: {
    font_size: "30px",
    line_height: "34px",
    font_weight: "700",
    m_font_size: "27px",
    m_line_height: "27px",
  },
  headline3alter: {
    font_size: "30px",
    line_height: "34px",
    font_weight: "400",
    m_font_size: "20px",
    m_line_height: "20px",
  },
  body3: {
    font_size: "30px",
    line_height: "30px",
    font_weight: "400",
    m_font_size: "15px",
    m_line_height: "15px",
  },
  body1: {
    font_size: "15px",
    line_height: "18px",
    font_weight: "400",
    m_font_size: "15px",
    m_line_height: "15px",
  },
  body2: {
    font_size: "15px",
    line_height: "24px",
    font_weight: "700",
    m_font_size: "15px",
    m_line_height: "24px",
  },
  caption: {
    font_size: "12px",
    line_height: "16px",
    font_weight: "400",
    m_font_size: "8px",
    m_line_height: "8px",
  },
};
