// importing the required chakra libraries
import { StyleFunctionProps } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

// declare a variable for fonts and set our fonts. I am using Inter with various backups but you can use `Times New Roman`. Note we can set different fonts for the body and heading.
// const fonts = {
//   ...chakraTheme.fonts,
//   body: { fontFamily: `'Manrope', sans-serif` },

//   heading: `'Manrope', sans-serif`,
// };

// declare a variable for our theme and pass our overrides in the e`xtendTheme` method from chakra
const chakratheme = extendTheme({
  // styles: {
  //   global: (props: StyleFunctionProps) => ({
  //     body: {
  //       fontFamily: '"Manrope",sans-serif"',
  //     },
  //   }),
  // },
});

// export our theme
export default chakratheme;
