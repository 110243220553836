import { createBrowserRouter } from "react-router-dom";
// import Root from "./components/Root";
// import Home from "./routes/Home";

// import NotFound from "./routes/NotFound";
// import Sales from "./routes/Sales";
// import About from "./routes/About";
// //import Privacy from "./routes/Privacy";
// import Term from "./routes/Term";
// import Support from "./routes/Support";
// import SimulatorT2 from "./routes/SimulatorT2";
// import SimulatorFA from "./routes/SimulatorFA";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <Root />,
    lazy: async () => {
      let Root = await import("./components/Root");
      return { Component: Root.default };
    },

    // errorElement: <NotFound />,
    children: [
      {
        path: "",
        // element: <Home />,
        lazy: async () => {
          let Home = await import("./routes/Home");
          return { Component: Home.default };
        },
      },
      {
        path: "Technology",
        lazy: async () => {
          let Technology = await import("./routes/Technology");
          return { Component: Technology.default };
        },
        // element: <Sales />,
      },
      {
        path: "simulatorT2",
        lazy: async () => {
          let SimulatorT2 = await import("./routes/SimulatorT2");
          return { Component: SimulatorT2.default };
        },

        // element: <SimulatorT2 />,
      },
      {
        path: "SimulatorFA",
        lazy: async () => {
          let SimulatorFA = await import("./routes/SimulatorFA");
          return { Component: SimulatorFA.default };
        },
        // element: <SimulatorFA />,
      },

      {
        path: "About",
        lazy: async () => {
          let About = await import("./routes/About");
          return { Component: About.default };
        },
        // element: <About />,
      },

      {
        // path: "Privacy",
        // element: <Privacy />,
        path: "Privacy",
        lazy: async () => {
          let Privacy = await import("./routes/Privacy");
          return { Component: Privacy.default };
        },
      },
      {
        path: "TermCondition",
        lazy: async () => {
          let Term = await import("./routes/Term");
          return { Component: Term.default };
        },
        // element: <Term />,
      },
      {
        path: "support",
        lazy: async () => {
          let Support = await import("./components/SupportForm");
          return { Component: Support.default };
        },
        // element: <Support />,
      },
      {
        path: "contact",
        lazy: async () => {
          let Contact = await import("./components/ContactForm");
          return { Component: Contact.default };
        },
        // element: <Support />,
      },
      {
        path: "*",
        lazy: async () => {
          let NotFound = await import("./routes/NotFound");
          return { Component: NotFound.default };
        },
      },
    ],
  },
]);

export default router;
